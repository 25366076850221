import React from "react"
import MediumButton from "../buttons/MediumButton";

const MiddleBannerMap = () =>{
    return (
    <>
    <section className="container-fluid bg-pri pt-md-4">
        <div className="row justify-content-center align-items-center gy-4">
            <div className="col-12 col-xl-6 text-center text-xl-start py-4 ps-xl-5">
        <h3 className="fw-600 text-capitalize pb-4 font-pri font-heading text-white" style={{lineHeight: "44px"}}>
        Keeping You and Your Family <br className="d-none d-sm-block"/> Healthy and Happy
        </h3>
        <a href="https://www.google.com/maps/place/United+States/@37.6,-95.665,4z/data=!3m1!4b1!4m6!3m5!1s0x54eab584e432360b:0x1c3bb99243deb742!8m2!3d37.09024!4d-95.712891!16zL20vMDljN3cw?entry=ttu" className="shadow" target="_blank">
        <MediumButton
            Title="View on Google Map"
            />
        </a>
            </div>
            <div className="col-12 col-xl-6 pb-3">
                <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25895663.485556163!2d-95.665!3d37.599999999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1686222431620!5m2!1sen!2s" width="600" height="380" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
        </div>
    </section>
    </>
    )
}

export default MiddleBannerMap;